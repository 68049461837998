import React from 'react';

import Icon from './Icon';

export default function StrokeCopyIcon({
  className = '',
  text = 'Copy',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 1.25C4.58579 1.25 4.25 1.58579 4.25 2C4.25 2.41421 4.58579 2.75 5 2.75H9C10.2426 2.75 11.25 3.75736 11.25 5V9C11.25 9.41421 11.5858 9.75 12 9.75C12.4142 9.75 12.75 9.41421 12.75 9V5C12.75 2.92893 11.0711 1.25 9 1.25H5Z"
          fill="currentColor"
        />
        <path
          d="M3 4.25C2.0335 4.25 1.25 5.0335 1.25 6V11C1.25 11.9665 2.0335 12.75 3 12.75H8C8.9665 12.75 9.75 11.9665 9.75 11V6C9.75 5.0335 8.9665 4.25 8 4.25H3ZM2.75 6C2.75 5.86193 2.86193 5.75 3 5.75H8C8.13807 5.75 8.25 5.86193 8.25 6V11C8.25 11.1381 8.13807 11.25 8 11.25H3C2.86193 11.25 2.75 11.1381 2.75 11V6Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
