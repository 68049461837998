import React from 'react';

import Icon from './Icon';

export default function CalendarIcon({ className = '' }: { className?: string }) {
  return (
    <Icon text="calendar">
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5.75 8C5.33579 8 5 8.33579 5 8.75C5 9.16421 5.33579 9.5 5.75 9.5H14.25C14.6642 9.5 15 9.16421 15 8.75C15 8.33579 14.6642 8 14.25 8H5.75Z" />
        <path d="M6.75 2.75C6.75 2.33579 6.41421 2 6 2C5.58579 2 5.25 2.33579 5.25 2.75V4H4.75C3.23122 4 2 5.23122 2 6.75V15.25C2 16.7688 3.23122 18 4.75 18H15.25C16.7688 18 18 16.7688 18 15.25V6.75C18 5.23122 16.7688 4 15.25 4H14.75V2.75C14.75 2.33579 14.4142 2 14 2C13.5858 2 13.25 2.33579 13.25 2.75V4H6.75V2.75ZM13.25 6.25C13.25 6.66421 13.5858 7 14 7C14.4142 7 14.75 6.66421 14.75 6.25V5.5H15.25C15.9404 5.5 16.5 6.05964 16.5 6.75V15.25C16.5 15.9404 15.9404 16.5 15.25 16.5H4.75C4.05964 16.5 3.5 15.9404 3.5 15.25V6.75C3.5 6.05964 4.05964 5.5 4.75 5.5H5.25V6.25C5.25 6.66421 5.58579 7 6 7C6.41421 7 6.75 6.66421 6.75 6.25V5.5H13.25V6.25Z" />
      </svg>
    </Icon>
  );
}
