import React from 'react';

import Icon from './Icon';

export default function ChromeIcon({ text = 'Chrome' }): JSX.Element {
  return (
    <Icon text={text}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="black" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.58665 10C7.58665 10.4773 7.72819 10.9439 7.99337 11.3408C8.25855 11.7377 8.63546 12.047 9.07644 12.2296C9.51742 12.4123 10.0027 12.4601 10.4708 12.367C10.9389 12.2739 11.369 12.044 11.7065 11.7065C12.044 11.369 12.2738 10.939 12.3669 10.4708C12.4601 10.0027 12.4123 9.51746 12.2296 9.07648C12.047 8.6355 11.7376 8.25859 11.3408 7.99341C10.9439 7.72823 10.4773 7.58669 9.99999 7.58669C9.35993 7.58669 8.74609 7.84095 8.2935 8.29354C7.84091 8.74612 7.58665 9.35996 7.58665 10Z" />
        <path d="M6.45999 7.76669C6.48994 7.80971 6.52981 7.84488 6.57622 7.86925C6.62264 7.89362 6.67423 7.90646 6.72665 7.90669C6.77831 7.90618 6.82917 7.89391 6.87538 7.87081C6.92159 7.8477 6.96192 7.81438 6.99332 7.77335C7.34138 7.30323 7.7945 6.92096 8.31653 6.65705C8.83856 6.39314 9.41504 6.2549 9.99999 6.25335H16.4867C16.5465 6.25282 16.6051 6.23651 16.6566 6.20608C16.7081 6.17565 16.7507 6.13216 16.78 6.08002C16.8092 6.02935 16.8246 5.97186 16.8246 5.91335C16.8246 5.85484 16.8092 5.79736 16.78 5.74669C16.1382 4.72754 15.2746 3.86647 14.2535 3.22768C13.2325 2.5889 12.0804 2.18889 10.8832 2.05751C9.68599 1.92613 8.47457 2.06675 7.3393 2.46889C6.20403 2.87104 5.17421 3.52432 4.32665 4.38002C4.27191 4.43592 4.23857 4.50929 4.23247 4.5873C4.22638 4.6653 4.24792 4.74296 4.29332 4.80669L6.45999 7.76669Z" />
        <path d="M17.7 7.83335C17.681 7.76287 17.6394 7.70057 17.5816 7.65601C17.5238 7.61145 17.453 7.5871 17.38 7.58669H13.5067C13.4483 7.58635 13.391 7.60163 13.3406 7.63094C13.2902 7.66025 13.2485 7.70252 13.22 7.75335C13.1908 7.80525 13.1754 7.8638 13.1754 7.92335C13.1754 7.98291 13.1908 8.04145 13.22 8.09335C13.4879 8.54174 13.6586 9.04145 13.7209 9.56005C13.7833 10.0787 13.7359 10.6046 13.5819 11.1037C13.4279 11.6028 13.1707 12.064 12.827 12.4573C12.4832 12.8506 12.0607 13.1672 11.5867 13.3867C11.5537 13.4062 11.5261 13.4337 11.5067 13.4667L9.28665 17.7334C9.27192 17.7574 9.26413 17.7851 9.26413 17.8134C9.26413 17.8416 9.27192 17.8693 9.28665 17.8934C9.30035 17.9174 9.3196 17.9378 9.34279 17.9529C9.36599 17.968 9.39246 17.9773 9.41999 17.98C9.60665 17.98 9.79999 17.98 9.99332 17.98C11.2271 17.98 12.4441 17.6947 13.5493 17.1463C14.6545 16.5979 15.6179 15.8013 16.3641 14.8188C17.1104 13.8363 17.6192 12.6945 17.851 11.4827C18.0827 10.2709 18.031 9.02187 17.7 7.83335Z" />
        <path d="M9.63999 13.8734C9.61764 13.8248 9.58324 13.7828 9.54006 13.7513C9.49689 13.7198 9.44637 13.6998 9.39332 13.6934C8.49709 13.5465 7.6849 13.0786 7.10834 12.3769C6.53177 11.6752 6.23023 10.7877 6.25999 9.88002C6.26143 9.80357 6.23804 9.72871 6.19332 9.66669L3.57332 6.08002C3.53991 6.03624 3.49636 6.00123 3.44643 5.97801C3.39649 5.95478 3.34166 5.94403 3.28665 5.94669C3.23012 5.94998 3.17536 5.96763 3.12754 5.99795C3.07971 6.02828 3.04041 6.07029 3.01332 6.12002C2.44134 7.15215 2.10477 8.29804 2.02775 9.47554C1.95072 10.6531 2.13513 11.833 2.56777 12.9309C3.00041 14.0287 3.67057 15.0173 4.5302 15.8257C5.38983 16.634 6.41765 17.2423 7.53999 17.6067C7.61564 17.6298 7.69697 17.6258 7.76998 17.5953C7.84299 17.5649 7.90311 17.51 7.93999 17.44L9.63332 14.1734C9.65887 14.1277 9.67284 14.0765 9.674 14.0242C9.67516 13.9719 9.66348 13.9201 9.63999 13.8734Z" />
      </svg>
    </Icon>
  );
}
