export default function getNextItemInArray<T>({
  currentIndex,
  direction = 'forward',
  list,
  shouldWrapAround = true,
}: {
  currentIndex: number;
  direction?: string;
  list: T[];
  shouldWrapAround?: boolean;
}): T | undefined {
  if (!list.length) {
    return;
  }

  const delta = direction === 'forward' ? 1 : -1;

  if (typeof list[currentIndex + delta] !== 'undefined') {
    return list[currentIndex + delta];
  }

  if (currentIndex === -1 || shouldWrapAround) {
    if (delta === 1) {
      return list[0];
    }

    return list[list.length - 1];
  }
}
