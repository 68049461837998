import React from 'react';

import styles from '../RightSidebar/RightSidebar.module.css';
import Icon from './Icon';

export default function FeedAnimatedIcon({ animate }: { animate: boolean }): JSX.Element {
  return (
    <Icon text="Provide Feedback">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        textRendering="geometricPrecision"
        shapeRendering="geometricPrecision"
        opacity="1"
        height="20"
        width="20"
        className={`${styles.a0_o_animation} ${animate ? styles.animateSVG : styles.staticSVG}`}
      >
        <g transform="translate(5.6449,11.3693) translate(-4.61163,-6.57486)">
          <g transform="translate(8.96676,4.9334) translate(-6.58547,-4.38487)">
            <g className={styles.a2_t_animation}>
              <path
                d="M5.97765,10.0223C5.15135,9.19604,4.03064,8.73183,2.86208,8.73183C4.0287,8.73183,5.15273,9.19742,5.97765,10.0223ZM5.97765,10.0223C6.80396,10.8487,7.26817,11.9694,7.26817,13.1379C7.26817,11.9713,6.80258,10.8473,5.97765,10.0223Z"
                stroke="#a3acb7"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                opacity="1"
                transform="translate(6.58544,4.65708) translate(-8,-8)"
                className={styles.a1_t_animation}
              />
            </g>
            <g className={styles.a4_t_animation}>
              <path
                d="M10.1308,5.86927C12.0502,7.80239,13.138,10.4139,13.138,13.138C13.138,10.4147,12.0566,7.79501,10.1308,5.86927ZM10.1308,5.86927C8.19769,3.94986,5.58614,2.86195,2.86196,2.86195C5.58537,2.86195,8.20508,3.94352,10.1308,5.86927Z"
                stroke="#a3acb7"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                opacity="1"
                transform="translate(6.58544,4.65708) rotate(-0.267408) translate(-8,-8)"
                className={styles.a3_t_animation}
              />
            </g>
            <g className={styles.a6_t_animation}>
              <g className={styles.a7_t_animation}>
                <path
                  d="M8.05431,7.94569C6.67341,6.57454,4.80796,5.79689,2.86195,5.79689M8.05431,7.94569C9.42546,9.32659,10.2031,11.1921,10.2031,13.138"
                  stroke="#a3acb7"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  opacity="1"
                  transform="translate(6.58544,4.65708) rotate(1.37112) translate(-8,-8)"
                  className={styles.a5_t_animation}
                />
              </g>
            </g>
          </g>
          <g id="check-2" className={styles.a8_t_animation}>
            <g className={styles.a9_t_animation}>
              <path
                d="M9.62373,9.53737C7.70116,8.64086,3.85603,6.84784,1.93346,5.95133M9.62373,9.53737C9.02606,10.8191,8.72722,11.4599,7.83072,13.3825"
                stroke="#a3acb7"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                opacity="0"
                transform="translate(8.96673,5.20561) rotate(1.37112) translate(-8,-8)"
                className={styles.check_t_animation}
              />
            </g>
          </g>
        </g>
      </svg>
    </Icon>
  );
}
