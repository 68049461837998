import { useCallback } from 'react';

import { AnyDocument, PartialDocument } from '../types';
import useGlobalStateWithFallback from './utils/useGlobalStateWithFallback';

export const useDocRSSFeed = (
  doc: AnyDocument | PartialDocument<AnyDocument, 'source_specific_data'> | null,
) => {
  const allRssFeeds = useGlobalStateWithFallback(
    {},
    useCallback((state) => state.persistent.rssFeeds, []),
  );
  const docFeedId = doc?.source_specific_data?.rss_feed;
  return docFeedId ? { ...allRssFeeds[docFeedId], id: docFeedId } : undefined;
};
