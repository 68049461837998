import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useMemo, useState } from 'react';

import { AnyDocument } from '../../../../shared/types';
import { ShortcutId } from '../../../../shared/types/keyboardShortcuts';
import { useShortcutsMap } from '../../utils/shortcuts';
import Button from '../Button';
import Tooltip from '../Tooltip';
import {
  getExportHighlightsDownloadOption,
  getExportHighlightsEditTemplateOption,
  getExportHighlightsOption,
  getSeparatorOption,
} from './docOptions';
import { Dropdown } from './Dropdown';
import styles from './DropdownNotebookExport.module.css';

export default function DropdownNotebookExport({ docId }: { docId: AnyDocument['id'] }) {
  const [isOpen, setIsOpen] = useState(false);
  const shortcutsMap = useShortcutsMap();

  const options = useMemo(() => {
    return [
      getExportHighlightsOption({ docId, shortcut: shortcutsMap[ShortcutId.ExportCopyToClipboard] }),
      getExportHighlightsDownloadOption({
        docId,
        shortcut: shortcutsMap[ShortcutId.ExportDownloadAnnotations],
      }),
      getSeparatorOption(),
      getExportHighlightsEditTemplateOption(),
    ];
  }, [docId, shortcutsMap]);

  const triggerElement = (
    <Tooltip content="Export annotations to clipboard or Markdown" placement="top-start">
      <DropdownMenu.Trigger asChild>
        <Button>Export</Button>
      </DropdownMenu.Trigger>
    </Tooltip>
  );

  return (
    <Dropdown
      trigger={triggerElement}
      options={options}
      isOpen={isOpen}
      contentClassName={styles.dropdownContent}
      side="top"
      setIsOpen={setIsOpen}
    />
  );
}
