import React from 'react';

import Icon from './Icon';

export default function StrokeScrollIcon({
  className = '',
  text = 'Scroll',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.46967 8.53033C7.17678 8.23744 7.17678 7.76256 7.46967 7.46967L9.46967 5.46967C9.76256 5.17678 10.2374 5.17678 10.5303 5.46967L12.5303 7.46967C12.8232 7.76256 12.8232 8.23744 12.5303 8.53033C12.2374 8.82322 11.7626 8.82322 11.4697 8.53033L10 7.06066L8.53033 8.53033C8.23744 8.82322 7.76256 8.82322 7.46967 8.53033Z"
          fill="#616C77"
        />
        <path
          d="M7.46967 11.4697C7.76256 11.1768 8.23744 11.1768 8.53033 11.4697L10 12.9393L11.4697 11.4697C11.7626 11.1768 12.2374 11.1768 12.5303 11.4697C12.8232 11.7626 12.8232 12.2374 12.5303 12.5303L10.5303 14.5303C10.2374 14.8232 9.76256 14.8232 9.46967 14.5303L7.46967 12.5303C7.17678 12.2374 7.17678 11.7626 7.46967 11.4697Z"
          fill="#616C77"
        />
        <path
          d="M10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1ZM2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10Z"
          fill="#616C77"
        />
      </svg>
    </Icon>
  );
}
