import React from 'react';

import Icon from './Icon';

export default function NoItemSelectedIcon({ className = '' }: { className?: string }): JSX.Element {
  return (
    <Icon text="NoItemSelected">
      <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.25 10.625V3.774V3.774C19.25 2.794 18.456 2 17.476 2H3.774C2.794 2 2 2.794 2 3.774V17.476C2 18.456 2.794 19.25 3.774 19.25H12"
          stroke="#A3ACB7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.191 20.928H19.299L17.447 22.764C16.937 23.269 16.072 22.908 16.072 22.191V14.808C16.072 14.09 16.941 13.729 17.449 14.238L22.762 19.551C23.27 20.059 22.911 20.928 22.191 20.928V20.928Z"
          stroke="#A3ACB7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.515 10.625H10.255"
          stroke="#A3ACB7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.302 10.448C6.4 10.546 6.4 10.704 6.302 10.802C6.204 10.9 6.046 10.9 5.948 10.802C5.85 10.704 5.85 10.546 5.948 10.448C6.046 10.351 6.204 10.351 6.302 10.448"
          stroke="#A3ACB7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.515 6.125H10.255"
          stroke="#A3ACB7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.302 5.951C6.4 6.049 6.4 6.207 6.302 6.305C6.204 6.403 6.046 6.403 5.948 6.305C5.85 6.207 5.85 6.049 5.948 5.951C6.046 5.853 6.204 5.854 6.302 5.951"
          stroke="#A3ACB7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 15.125H10.345"
          stroke="#A3ACB7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.39001 14.952C6.48801 15.05 6.48801 15.208 6.39001 15.306C6.29201 15.404 6.13401 15.404 6.03601 15.306C5.93801 15.208 5.93801 15.05 6.03601 14.952C6.13401 14.854 6.29201 14.855 6.39001 14.952"
          stroke="#A3ACB7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}
