import { AnyDocument } from '../types';
import getDocumentTitle from '../utils/getDocumentTitle';
import copyTextToClipboard from './utils/copyTextToClipboard';

export default (document: AnyDocument): void => {
  const title = getDocumentTitle(document);
  copyTextToClipboard(title, {
    successToastMessage: 'Copied title to clipboard',
  });
};
