import DOMPurify from 'dompurify';

const URL_REGEX =
  /\[([^\]]+)\]\(((https?:\/\/|mailto:)[-A-Z0-9+&@#()/%?=~_|!:,.;]*[-A-Z0-9+()&@#/%=~_|])\)/gi;
const IMG_REGEX = /!\[([^\]])*\]\(((https?:\/\/)[-A-Z0-9+&@()#/*%?=~_|!:,.;]*[-A-Z0-9+&@#()/%=~_|])\)/gi;
const HYPERHIGHLIGHT_REGEX = /__(\S[\s\S]*?)__/g;
const BOLD_REGEX = /\*\*(\S[\s\S]*?)\*\*/g;
const BOLD_ITALICS_REGEX = /\*\*\*(\S[\s\S]*?)\*\*\*/g;
const ITALICS_REGEX = /\*(\S[\s\S]*?)\*/g;

export function restrictedMarkdownToHtml(markdown: string): string {
  return DOMPurify.sanitize(
    markdown
      .replace(IMG_REGEX, '<img src="$2" alt="$1" class="in-highlight-image"/>')
      .replace(URL_REGEX, '<a href="$2" target="_blank">$1</a>')
      .replace(HYPERHIGHLIGHT_REGEX, '<u>$1</u>')
      .replace(BOLD_ITALICS_REGEX, '<strong><em>$1</em></strong>')
      .replace(BOLD_REGEX, '<strong>$1</strong>')
      .replace(ITALICS_REGEX, '<em>$1</em>')
      .replace(/\n/g, '<br>'),
  );
}
